import React from 'react'

import styles from './RichText.module.css'

const RichText = ({ content }) => (
  <div
    className={styles.component}
    dangerouslySetInnerHTML={{ __html: content }}
  />
)

export default RichText
