import React from 'react'
import { graphql } from 'gatsby'
import Helmet from 'react-helmet'
import * as Hero from '../components/Hero'
import Centered from '../components/Centered'
import RichText from '../components/RichText'

import digiaLogo from '../../assets/images/digia-logo.png'

import styles from './TestPage.module.css'

export const query = graphql`
  query TestPage($slug: String!) {
    test(fields: { slug: { eq: $slug } }) {
      title
      description
      body
      fields {
        slug
      }
    }
    site {
      siteMetadata {
        title
        description
        author
        siteUrl
        githubUrl
      }
    }
  }
`

const TestPage = ({ data: { test, site: { siteMetadata } } }) => (
  <div className={styles.component}>
    <Helmet
      title={test.title + ' · ' + siteMetadata.title}
      meta={[
        { name: 'description', content: test.description },
        { name: 'theme-color', content: '#ac0d16' },
        { property: 'og:title', content: test.title },
        { property: 'og:type', content: 'article' },
        {
          property: 'og:url',
          content: siteMetadata.siteUrl + test.fields.slug,
        },
        { property: 'og:description', content: test.description },
        { property: 'og:image', content: siteMetadata.siteUrl + digiaLogo },
        { property: 'og:image:width', content: '1200' },
        { property: 'og:image:height', content: '990' },
      ]}
      link={[
        { rel: 'canonical', href: siteMetadata.githubUrl + test.fields.slug },
      ]}
    />
    <Hero.Box className={styles.hero}>
      <Hero.Back to='/join-us' />
      <Hero.Title>{test.title}</Hero.Title>
      <Hero.Lead>{test.description}</Hero.Lead>
    </Hero.Box>
    <Centered>
      <div className={styles.content}>
        <div className={styles.main}>
          <RichText content={test.body} />
        </div>
      </div>
    </Centered>
  </div>
)

export default TestPage
